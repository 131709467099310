//
// home.scss
//

.home-btn {
  position: absolute;
  bottom: 40px;
  right: 30px;
  z-index: 1;
}

.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.contain-box {
  // height: 100vh;
  position: relative;
}

// home-3

#countdown .count-down,
#eventdown .count-down {
  display: inline-block;
  margin: 10px;
  text-align: center;
  background: rgba($white, 0.2);
  color: $white;
  height: 115px;
  width: 20%;
  border-radius: 5px;
}

#countdown .count-down .count-number,
#eventdown .count-down .count-number {
  font-size: 40px;
  line-height: 95px;
}

#countdown .count-down .count-head,
#eventdown .count-down .count-head {
  display: block;
  position: relative;
  transform: translateY(-20px);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

// home-4

.search-form {
  input {
    padding: 15px 20px;
    width: 100%;
    font-size: 17px;
    color: $muted !important;
    border: 1px solid #ccc;
    outline: none !important;
    padding-right: 220px;
    padding-left: 30px;
    background-color: $white;
    border-radius: 30px;
    box-shadow: $box-shadow;
  }
  button {
    position: absolute;
    // top: 6px;
    // right: 6px;
    outline: none !important;
    border-radius: 30px;
    font-size: 16px;
    padding: 9px 30px;
  }
  form {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
  }
}

// Home-5

.slidero {
  position: relative;
  z-index: 1;
}
