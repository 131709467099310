
  //
// contect.scss
//


.error {
    margin: 8px 0px;
    display: none;
    color: red;
  }
  
  #ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
  }
  
  .error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    color: #f01414;
    border: 2px solid #f01414;
  }
  
  .contact-loader {
    display: none;
  }

  .contact-info{
    box-shadow: $box-shadow;
  }
  .custom-form {
    .form-control {
      height: 44px;
      z-index: 2;
      border: 1px solid lighten($muted, 30%);
      font-size: 15px;
      color: $muted;
      &:focus {
        outline: 0;
        box-shadow: none;
        border: 1px solid $primary;
      }
    }
  }

  .custom-form textarea.form-control {
    height: auto !important;
  }

  .contact-icon{
    i{
      width: 46px;
      height: 46px;
      background: $primary;
      color: $white;
      font-size: 24px;
      border-radius: 50%;
      line-height: 46px;
      text-align: center;

    }
  }

  .contact-info{
    overflow: hidden;
  }