//
// menu.scss
//

.header{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    padding: 25px 0;
}


