//
// helper.scss
//


.section {
    padding-top: 100px;    
    padding-bottom: 100px;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
}

.vertical-content{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.bg-overlay {
    background-color: rgba(14, 21, 33, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.bg-gradient{
    background: $bg-gradient;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.9;
}

hr {
    border-top: 1px solid #f0f0f0;
}


// Font Size

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-17 {
    font-size: 17px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-24 {
    font-size: 24px;
}

// Line-height

.line-height_1_4 {
    line-height: 1.4;
}

.line-height_1_6 {
    line-height: 1.6;
}

.line-height_1_8 {
    line-height: 1.8;
}


// Font-Weight

.font-weight-600{
    font-weight: 600;
}