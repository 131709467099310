/* Template Name: Domania
   Author: Themesdesign
   Version: 1.0.0
   Created: September 2020
   File Description: Main Css file of the template
*/

/****************************************\

01.general
02.helper
03.components
04.menu
05.home
06.contact
07.footer
08.responsive

*****************************************/

@import "variables";
@import "general";
@import "helper";
@import "components";
@import "menu";
@import "home";
@import "contact";
@import "footer";
@import "responsive";
@import "../v4-style.scss";

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.bg-gradient-success {
  background: linear-gradient(90deg, #0010cb, #6c92ff 60%, #0010cb);
}
.bg-gradient-protected {
  background: linear-gradient(90deg, #1b5087, #4300e5 60%, #065d87);
}
/* ANIMATION */
.pulse-animate {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(243, 241, 241, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.rotate-animate {
  width: 100px;
  animation: rotation 1s 1 cubic-bezier(0, 0, 0, 1.66);
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
