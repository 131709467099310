@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,200&display=swap");

@font-face {
  font-family: ErasSlim;
  src: url(../fonts/ron-fonts/ERASMD.TTF);
}
.btn-border-primary {
  border: 1px solid #66552b !important;
}
.btn-sm-slim {
  padding: 3px 14px;
  font-size: 0.7rem;
}
.form-control-sm {
  padding: 1.01rem 0.5rem;
}
.font-monts-400 {
  font-family: Montserrat;
  font-weight: 400 !important;
}

.font-monts-600 {
  font-family: Montserrat;
  font-weight: 600;
}
.font-inter-400 {
  font-family: Inter;
  font-weight: 400 !important;
}
.font-inter-300 {
  font-family: Inter;
  font-weight: 300 !important;
}
.font-inter-700 {
  font-family: Inter;
  font-weight: 700 !important;
}
.font-verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
}
.font-monts-300 {
  font-family: Montserrat;
  font-weight: 600;
}
@font-face {
  font-family: ErasITCBold;
  src: url(../fonts/ron-fonts/ERASBD.TTF);
}
@font-face {
  font-family: ArialRounded;
  src: url(../fonts/ron-fonts/ARLRDBD.TTF);
}
.font-ErasSlim {
  font-family: ErasSlim, sans-serif;
}
.icon-fontawesome-fix {
  width: 16px !important;
  margin-right: 12px;
}
.icon-width {
  width: 20px;
}
.icon-width-50 {
  width: 50px;
}
.icon-width-75 {
  width: 75px;
}
.google-icon {
  width: 16px;
  margin-right: 12px;
  margin-bottom: 1px;
}
.bg-offwhite {
  background-color: #e5e5e5;
}
/* PROFILE =- START */

.profile-tab-fix-height {
  height: 500px; /* set your desired height here */
  overflow-y: auto; /* add a scrollbar if the content overflows */
}
.country-dropdown {
  position: relative;
  top: -8px;
  left: 2px;
  border: none !important;
  #rfs-btn {
    width: 103%;
  }
}
.main-heading-numeric {
  font-family: ErasITCBold, cursive !important;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(150px);
  animation: fade-in-right 1s forwards;
}
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-up {
  opacity: 0;
  transform: translateY(150px);
  animation: fade-in-up 1.3s forwards;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-bottom {
  opacity: 0;
  transform: translateY(-50px);
  animation: fade-in-bottom 1.3s forwards;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-left {
  opacity: 0;
  transform: translateX(-150px);
  animation: fade-in-left 1s forwards;
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.secretKey {
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}
.table-orders {
  font-family: verdana, sans-serif;
  font-size: 13px;
}
.social-link {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
}

.social-link:hover,
.social-link:focus {
  background: #ddd;
  text-decoration: none;
  color: #555;
}
.btn-close::before {
  content: "X";
  visibility: visible;
  color: "black";
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.btn-close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 32px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}
/* PROFILE END*/
/* Innova */
.InovuaReactDataGrid__column-header__content {
  font-size: 12px !important;
}
.yellow {
  color: #ffd25c;
}

/* Innova END*/

/* ANIMATED Buttons */
/* ~~~~~~~ INIT. BTN ~~~~~~~ */

.from-center {
  position: relative;
  color: var(--inv);
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  border-radius: 10px;
}

.from-center.active {
  border-bottom: 2px solid #ccc;
}

.from-center:before,
.from-center:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.from-center:hover {
  color: var(--def);
  transition-delay: 0.5s;
}

.from-center:hover:before {
  transition-delay: 0s;
}

.from-center:hover:after {
  background: var(--inv);
  transition-delay: 0.35s;
}

/* From center */

.from-center:before {
  top: 0;
  left: 50%;
  height: 100%;
  width: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
  border-radius: 10px;
  border-radius: 10px;
}

.from-center:after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: var(--inv);
  border-radius: 10px;
  border-radius: 10px;
}

.from-center:hover:before {
  left: 0;
  width: 100%;
}

.from-center:hover:after {
  top: 0;
  height: 100%;
}

/* ~~~~~~~~~~~~ GLOBAL SETTINGS ~~~~~~~~~~~~ */

*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  --def: #96b7c4;
  --inv: #8b572a;
}
